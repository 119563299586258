import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";

export const createAadhaarDataStore = createAsyncThunk(
  END_POINTS.CREATE_AADHAAR_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_AADHAAR_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createPANDataStore = createAsyncThunk(
  END_POINTS.CREATE_PAN_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(END_POINTS.CREATE_PAN_DATA_URL, params);
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPANDataStore = createAsyncThunk(
  END_POINTS.FETCH_PAN_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_PAN_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPassportDataStore = createAsyncThunk(
  END_POINTS.FETCH_PASSPORT_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_PASSPORT_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createPassportDataStore = createAsyncThunk(
  END_POINTS.CREATE_PASSPORT_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_PASSPORT_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAttendanceTempCard = createAsyncThunk(
  END_POINTS.ATTENDANCE_HISTORY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.ATTENDANCE_HISTORY}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchPersonalDataStore = createAsyncThunk(
  END_POINTS.FETCH_PERSONAL_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_PERSONAL_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createPersonalDataStore = createAsyncThunk(
  END_POINTS.CREATE_PERSONAL_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_PERSONAL_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createPersonalContactDataStore = createAsyncThunk(
  END_POINTS.CREATE_PERSONAL_CONTACT_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_PERSONAL_CONTACT_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAadhaarDataStore = createAsyncThunk(
  END_POINTS.FETCH_AADHAAR_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_AADHAAR_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchSeparationDataStore = createAsyncThunk(
  END_POINTS.FETCH_SEPARATION_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_SEPARATION_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchResignationHistoryData = createAsyncThunk(
  END_POINTS.FETCH_RESIGNATION_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_RESIGNATION_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchResignationRevokeData = createAsyncThunk(
  END_POINTS.Fetch_REVOKE_REQUEST_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.Fetch_REVOKE_REQUEST_URL}/${params.separationId}`,
        { revokeReason: params.revokeReason }
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createResignationRevokeRequest = createAsyncThunk(
  END_POINTS.CREATE_REVOKE_REQUEST_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.CREATE_REVOKE_REQUEST_URL}/${params}`
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchSeparationReasonDataStore = createAsyncThunk(
  END_POINTS.FETCH_SEPARATION_REASON_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_SEPARATION_REASON_DATA_URL}?isResignInitiated=${params?.is_resign_initiated}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const createSeparationDataStore = createAsyncThunk(
  END_POINTS.CREATE_SEPARATION_REASON_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_SEPARATION_REASON_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const createInitationSeparationDataStore = createAsyncThunk(
  END_POINTS.CREATE_INITATION_SEPARATION_REASON_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_INITATION_SEPARATION_REASON_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const createEmergencyContactDataStore = createAsyncThunk(
  END_POINTS.CREATE_EMERGENCY_CONTACT_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_EMERGENCY_CONTACT_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmergencyContactDataStore = createAsyncThunk(
  END_POINTS.FETCH_EMERGENCY_CONTACT_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_EMERGENCY_CONTACT_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchNoticeLeaveDataStore = createAsyncThunk(
  END_POINTS.FETCH_LEAVE_NOTICE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_LEAVE_NOTICE_DATA_URL}/${params}`
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchVaccineDetails = createAsyncThunk(
  END_POINTS.VACCINE_DETAILS_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.VACCINE_DETAILS_URL}/${params}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchStateListDataStore = createAsyncThunk(
  END_POINTS.FETCH_STATE_LIST_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_STATE_LIST_DATA_URL}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createVaccineDataStore = createAsyncThunk(
  END_POINTS.CREATE_VACCINE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_VACCINE_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchCityListDataStore = createAsyncThunk(
  END_POINTS.FETCH_CITY_LIST_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_CITY_LIST_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchBasicProfileDataStore = createAsyncThunk(
  END_POINTS.FETCH_BASIC_PROFILE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_BASIC_PROFILE_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchProfileCompletedDataStore = createAsyncThunk(
  END_POINTS.PROFILE_COMPLETED,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.PROFILE_COMPLETED}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);


export const getEmployeeCommitmentDataStore = createAsyncThunk(
  END_POINTS.PROFILE_COMPLETED,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.PROFILE_COMPLETED}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchBasicProfileImageStore = createAsyncThunk(
  END_POINTS.FETCH_BASIC_PROFILE_IMAGE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_BASIC_PROFILE_IMAGE_DATA_URL}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchCompanyListDataStore = createAsyncThunk(
  END_POINTS.FETCH_COMPANY_LIST_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_COMPANY_LIST_DATA_URL}`
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchApproverByIdStore = createAsyncThunk(
  END_POINTS.FETCH_APPROVER_BY_ID_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_APPROVER_BY_ID_URL}?EmployeeId=${params}`
      );
      return data.data.leaveApproverData;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEventListDataStore = createAsyncThunk(
  END_POINTS.FETCH_EVENT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_EVENT_LIST}`);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateLeaveApproverDataStore = createAsyncThunk(
  END_POINTS.UPDATE_LEAVE_APPROVER_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_LEAVE_APPROVER_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateBasicProfileDataStore = createAsyncThunk(
  END_POINTS.UPDATE_BASIC_PROFILE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_BASIC_PROFILE_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateEmploymentDetailsDataStore = createAsyncThunk(
  END_POINTS.UPDATE_EMPLOYMENT_DETAILS_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_EMPLOYMENT_DETAILS_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateBioDataStore = createAsyncThunk(
  END_POINTS.UPDATE_BIO_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_BIO_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateImageDataStore = createAsyncThunk(
  END_POINTS.UPDATE_IMAGE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_IMAGE_DATA_URL}/${params?.employeeId}`,
        params?.data,
        params?.headers
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateResumeDataStore = createAsyncThunk(
  END_POINTS.UPDATE_RESUME_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_RESUME_DATA_URL}/${params?.employeeId}`,
        params?.data,
        params?.headers
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateJobProfileDataStore = createAsyncThunk(
  END_POINTS.UPDATE_JOB_PROFILE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_JOB_PROFILE_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchAllEmployeesData = createAsyncThunk(
  END_POINTS.FETCH_EMPLOYEE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_EMPLOYEE_DATA_URL}?page=${
          params?.page || ""
        }&limit=${params?.limit || ""}&sortField=${
          params?.sortField || ""
        }&sortOrder=${params?.sortOrder || ""}&filterField=${
          params?.filterField || ""
        }&filterValue=${params?.filterValue || ""}&search=${
          params.search || ""
        }`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateVaccineDataStore = createAsyncThunk(
  END_POINTS.UPDATE_VACCINE_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_VACCINE_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchFamilyDetails = createAsyncThunk(
  END_POINTS.IMMEDIATE_FAMILY_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.IMMEDIATE_FAMILY_DETAILS}/${params}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createFamilyDetailsStore = createAsyncThunk(
  END_POINTS.CREATE_FAMILY_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_FAMILY_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateFamilyDetailsStore = createAsyncThunk(
  END_POINTS.UPDATE_FAMILY_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_FAMILY_DATA_URL,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updatePassportUploadStore = createAsyncThunk(
  END_POINTS.UPDATE_PASSPORT_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_PASSPORT_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updatePANUploadStore = createAsyncThunk(
  END_POINTS.UPDATE_PAN_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_PAN_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateDocumentUploadStore = createAsyncThunk(
  END_POINTS.UPLOAD_DOCUMENTS_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPLOAD_DOCUMENTS_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createAadhaarUploadStore = createAsyncThunk(
  END_POINTS.CREATE_AADHAAR_PDF_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.CREATE_AADHAAR_PDF_DATA_URL}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const deletePANStore = createAsyncThunk(
  END_POINTS.DELETE_PAN_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.delete(
        `${END_POINTS.DELETE_PAN_DATA_URL}/${params?.employeeId}/${params?.fileName}`
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const deleteDocumentsStore = createAsyncThunk(
  END_POINTS.DELETE_DOCUMENTS_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.delete(
        `${END_POINTS.DELETE_DOCUMENTS_DATA_URL}/${params?.employeeId}/${params?.fileName}`
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const deletePassportStore = createAsyncThunk(
  END_POINTS.DELETE_PASSPORT_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.delete(
        `${END_POINTS.DELETE_PASSPORT_DATA_URL}/${params?.employeeId}/${params?.fileName}`
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteAadhaarStore = createAsyncThunk(
  END_POINTS.DELETE_AADHAAR_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.delete(
        `${END_POINTS.DELETE_AADHAAR_DATA_URL}/${params?.employeeId}`
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeSkillsSet = createAsyncThunk(
  END_POINTS.EMPLOYEE_SKILLS_SET_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_SKILLS_SET_DETAILS}/${params}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchWorkModePopUp = createAsyncThunk(
  END_POINTS.FETCH_WORKMODE_POPUP,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_WORKMODE_POPUP}?employeeId=${params?.employeeId}&fromDate=${params?.fromDate}&toDate=${params?.toDate}&workMode=${params?.workMode}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeSkillsSetStore = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_SKILLS_SET,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_EMPLOYEE_SKILLS_SET,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createWorkModeStore = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_WORK_MODE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_EMPLOYEE_WORK_MODE,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createWorkShiftStore = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_WORK_SHIFT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_EMPLOYEE_WORK_SHIFT,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getWorkShiftStore = createAsyncThunk(
  END_POINTS.GET_EMPLOYEE_WORK_SHIFT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEE_WORK_SHIFT}/${params}`
      );
      return data.data;
    } catch (error) {
      console.log(error, "error");
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getResumeDownloadLinkStore = createAsyncThunk(
  END_POINTS.GET_RESUME_DOWNLOAD_LINK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_RESUME_DOWNLOAD_LINK}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getDocumentsDownloadLinkStore = createAsyncThunk(
  END_POINTS.GET_DOCUMENTS_DOWNLOAD_LINK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_DOCUMENTS_DOWNLOAD_LINK}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeSkillsSetStore = createAsyncThunk(
  END_POINTS.UPDATE_EMPLOYEE_SKILLS_SET,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_EMPLOYEE_SKILLS_SET}/${params.employee_id}`,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchEmployeeCertification = createAsyncThunk(
  END_POINTS.EMPLOYEE_CERTIFICATION_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_CERTIFICATION_DETAILS}/${params}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getEmployeeCommitment = createAsyncThunk(
  END_POINTS.GET_EMPLOYEE_COMMITMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEE_COMMITMENT}/${params.employeeId}`
      );
      //toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeCertificationStore = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_CERTIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_EMPLOYEE_CERTIFICATION,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeCommitment = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_COMMITMENT,
  async (params, { rejectWithValue }) => {
    try {
      console.log(params);
      const { data } = await http.post(
        `${END_POINTS.CREATE_EMPLOYEE_COMMITMENT}/${params.employeeId}`,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeCertificationStore = createAsyncThunk(
  END_POINTS.UPDATE_EMPLOYEE_CERTIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_EMPLOYEE_CERTIFICATION,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeCommitment = createAsyncThunk(
  END_POINTS.UPDATE_EMPLOYEE_COMMITMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_EMPLOYEE_COMMITMENT,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEducationType = createAsyncThunk(
  END_POINTS.FETCH_EDUCATION_LEVEL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_EDUCATION_LEVEL}`);
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeEducationQualification = createAsyncThunk(
  END_POINTS.EMPLOYEE_EDUCATION_QUALIFICATION_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_EDUCATION_QUALIFICATION_DETAILS}/${params}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeEducationQualificationStore = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_EDUCATION_QUALIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_EMPLOYEE_EDUCATION_QUALIFICATION,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeEducationQualificationStore = createAsyncThunk(
  END_POINTS.UPDATE_EMPLOYEE_EDUCATION_QUALIFICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_EMPLOYEE_EDUCATION_QUALIFICATION,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const uploadEducationDocuments = createAsyncThunk(
  END_POINTS.UPLOAD_EDUCATION_DOCUMENTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.UPLOAD_EDUCATION_DOCUMENTS}/${params?.employeeId}?level_id=${params?.level_id}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteEducationDocuments = createAsyncThunk(
  END_POINTS.DELETE_EDUCATION_DOCUMENTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.delete(
        `${END_POINTS.DELETE_EDUCATION_DOCUMENTS}/${params?.employeeId}?level_id=${params?.level_id}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeWorkExperience = createAsyncThunk(
  END_POINTS.EMPLOYEE_WORK_EXPERIENCE_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_WORK_EXPERIENCE_DETAILS}/${params}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeWorkExperienceStore = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_WORK_EXPERIENCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.CREATE_EMPLOYEE_WORK_EXPERIENCE,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeWorkExperienceStore = createAsyncThunk(
  END_POINTS.UPDATE_EMPLOYEE_WORK_EXPERIENCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_EMPLOYEE_WORK_EXPERIENCE,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getEmployeesOfProject = createAsyncThunk(
  END_POINTS.GET_EMPLOYEES_OF_PROJECT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEES_OF_PROJECT}/${params?.employee_id}`,
        { params: { team: params?.team, currentDate: params?.currentDate } }
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createUpdateBankDetails = createAsyncThunk(
  END_POINTS.BANK_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.BANK_DETAILS}/${params.employeeId}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);



export const getBankDetails = createAsyncThunk(
  END_POINTS.CREATE_UPDATE_BANK_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.BANK_DETAILS}/${params.employeeId}`
      );
      //toast.success(data?.message);
      return data?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeHistory = createAsyncThunk(
  END_POINTS.FETCH_EMPLOYEE_HISTORY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_EMPLOYEE_HISTORY}/${params}`
      );
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const uploadPreviousCompanyDocuments = createAsyncThunk(
  END_POINTS.UPLOAD_PREVIOUS_COMPANY_DOCUMENTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.UPLOAD_PREVIOUS_COMPANY_DOCUMENTS}/${params?.employee_id}?file_name=${params?.file_name}&company_name=${params?.company_name}&experience_id=${params?.experience_id}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deletePreviousCompanyDocuments = createAsyncThunk(
  END_POINTS.DELETE_PREVIOUS_COMPANY_DOCUMENTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.delete(
        `${END_POINTS.DELETE_PREVIOUS_COMPANY_DOCUMENTS}/${params?.employee_id}?file_name=${params?.file_name}&company_name=${params?.company_name}&experience_id=${params?.experience_id}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getPreviousCompanyDocuments = createAsyncThunk(
  END_POINTS.GET_PREVIOUS_COMPANY_DOCUMENTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_PREVIOUS_COMPANY_DOCUMENTS}/${params?.employee_id}?company_name=${params?.company_name}&experience_id=${params?.experience_id}`,
        params?.data
      );
      // toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getResignationData = createAsyncThunk(
  END_POINTS.RESIGNATION_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.RESIGNATION_DATA}`,
        params?.data
      );
      // toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const uploadCertificationDocuments = createAsyncThunk(
  END_POINTS.UPLOAD_CERTIFICATION_DOCUMENTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.UPLOAD_CERTIFICATION_DOCUMENTS}/${params?.employeeId}?certification_id=${params?.certification_id}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getResignationMonthWiseData = createAsyncThunk(
  END_POINTS.MONTHLY_RESIGNATION_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.MONTHLY_RESIGNATION_DATA}`,
        params?.data
      );
      // toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getResignationReportData = createAsyncThunk(
  `${END_POINTS.RESIGNATION_REPORT_DATA}`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.RESIGNATION_REPORT_DATA}?page=${
          params?.page || ""
        }&limit=${params?.limit || ""}&sortField=${
          params?.sortField || ""
        }&sortOrder=${params?.sortOrder || ""}&filterField=${
          params?.filterField || ""
        }&filterValue=${params?.filterValue || ""}&search=${
          params?.search || ""
        }`
      );
      // toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchNewJoineeList = createAsyncThunk(
  `${END_POINTS.NEW_JOINEE}`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.NEW_JOINEE}`);
      // toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchRemoteList = createAsyncThunk(
  `${END_POINTS.REMOTELY_WORKING}`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.REMOTELY_WORKING}`);
      // toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAllPosts = createAsyncThunk(
  `${END_POINTS.ALL_POSTS}`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.ALL_POSTS}`);
      // toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const deleteCertificationDocuments = createAsyncThunk(
  END_POINTS.DELETE_CERTIFICATION_DOCUMENTS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.delete(
        `${END_POINTS.DELETE_CERTIFICATION_DOCUMENTS}/${params?.employeeId}?certification_id=${params?.certification_id}`,
        params?.data
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchUserListData = createAsyncThunk(
  END_POINTS.FETCH_USER_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_USER_LIST}`, {
        params: {
          status: params?.status ?? "All",
          page: params?.page ?? 1,
          limit: params?.limit ?? 25,
          sortField: params?.sortField ?? "EmployeeId",
          sortOrder: params?.sortOrder ?? "ASC",
          search: params?.search ?? null,
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const updateUserById = createAsyncThunk(
  END_POINTS.UPDATE_USER_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_USER_BY_ID}/${params?.employeeId}`,
        params?.data
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const adminPasswordUpdate = createAsyncThunk(
  END_POINTS.ADMIN_PASSWORD_UPDATE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.ADMIN_PASSWORD_UPDATE}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchUserById = createAsyncThunk(
  END_POINTS.FETCH_USER_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_USER_BY_ID}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const resetLockUpdate = createAsyncThunk(
  END_POINTS.RESET_USER_LOCK,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.RESET_USER_LOCK}/${params}`
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const createseparationData = createAsyncThunk(
  END_POINTS.CREATE_SEPARATION_DATA_URL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.CREATE_SEPARATION_DATA_URL}`,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createRevokeApproval = createAsyncThunk(
  END_POINTS.FETCH_SEPARATION_REVOKE_APPROVAL,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.FETCH_SEPARATION_REVOKE_APPROVAL}`,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchResignationAppliedList = createAsyncThunk(
  `${END_POINTS.RESIGNATION_APPLIED_LIST}`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.RESIGNATION_APPLIED_LIST}?page=${params?.page}&limit=${
          params?.limit
        }&sortBy=${params?.sortBy}&order=${params?.order}&search=${
          params?.search
        }&filterOptions=${params?.filterOptions ?? null}`
      );
      // toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchApproverHistoryById = createAsyncThunk(
  END_POINTS.FETCH_APPROVER_HISTORY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_APPROVER_HISTORY}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchHolidayHistoryById = createAsyncThunk(
  END_POINTS.FETCH_HOLIDAY_HISTORY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_HOLIDAY_HISTORY}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  createAadhaarDataResponse: {
    isFetching: false,
    response: null,
  },
  createPANDataResponse: {
    isFetching: false,
    response: null,
  },
  getPANDataResponse: {
    isFetching: false,
    panData: null,
  },
  getPassportDataResponse: {
    isFetching: false,
    passportData: null,
  },
  createPassportDataResponse: {
    isFetching: false,
    response: null,
  },
  getPersonalDataResponse: {
    isFetching: false,
    personalData: null,
  },
  createPersonalDataResponse: {
    isFetching: false,
    response: null,
  },
  createPersonalContactDataResponse: {
    isFetching: false,
    response: null,
  },
  getAadhaarDataResponse: {
    isFetching: false,
    aadhaarData: null,
  },
  getSeparationDataResponse: {
    isFetching: false,
    separationData: null,
  },
  getSeparationReasonListResponse: {
    isFetching: false,
    separationListData: null,
  },
  createSeparationDataResponse: {
    isFetching: false,
    response: null,
  },
  createInitationSeparationDataResponse: {
    isFetching: false,
    response: null,
  },
  createEmergencyContactDataResponse: {
    isFetching: false,
    response: null,
  },
  getEmergencyContactReasonListResponse: {
    isFetching: false,
    emergencyContactListData: null,
  },
  getNoticeLeaveResponse: {
    isFetching: false,
    noticeLeaveData: null,
  },
  getStateListResponse: {
    isFetching: false,
    stateListData: null,
  },
  getCityListResponse: {
    isFetching: false,
    cityListData: null,
  },
  getBasicResponse: {
    isFetching: false,
    basicData: null,
  },
  getProfileResponse: {
    isFetching: false,
    profileCompleted: null,
  },
  getBasicImageResponse: {
    isFetching: false,
    image: null,
  },
  getCompanyListResponse: {
    isFetching: false,
    companyListData: null,
  },
  getApproverByEmployeeIdResponse: {
    isFetching: false,
    approverByEmployeeId: null,
  },
  getEventListResponse: {
    isFetching: false,
    eventList: null,
  },
  getAllEmployeeResponse: {
    isFetching: false,
    allEmployeeData: null,
  },
  vaccinationDetailsResponse: {
    isFetching: false,
    vaccineData: null,
  },
  familyDetailsResponse: {
    isFetching: false,
    familyData: null,
  },
  createVaccineDataResponse: {
    isFetching: false,
    response: null,
  },
  updateLeaveApproverDataResponse: {
    isFetching: false,
    response: null,
  },
  updateEmploymentDetailsResponse: {
    isFetching: false,
    response: null,
  },
  updateBioDataResponse: {
    isFetching: false,
    response: null,
  },
  updateImageResponse: {
    isFetching: false,
    response: null,
  },
  updateResumeResponse: {
    isFetching: false,
    response: null,
  },
  updateJobProfileResponse: {
    isFetching: false,
    response: null,
  },
  updateVaccineDataResponse: {
    isFetching: false,
    response: null,
  },
  createFamilyDataResponse: {
    isFetching: false,
    response: null,
  },
  updateFamilyDataResponse: {
    isFetching: false,
    response: null,
  },
  uploadPassportData: {
    isFetching: false,
    response: null,
  },
  uploadPANData: {
    isFetching: false,
    response: null,
  },
  uploadDocumentsData: {
    isFetching: false,
    response: null,
  },
  createAadhaarResponse: {
    isFetching: false,
    response: null,
  },
  deletePANData: {
    isFetching: false,
    response: null,
  },
  deleteDocuments: {
    isFetching: false,
    response: null,
  },
  deletePassportData: {
    isFetching: false,
    response: null,
  },
  deleteAadhaarData: {
    isFetching: false,
    response: null,
  },
  employeeSkillsSetResponse: {
    isFetching: false,
    skillData: null,
  },
  popUpResponse: {
    isFetching: false,
    popUpData: null,
  },
  createEmployeeSkillsSetResponse: {
    isFetching: false,
    response: null,
  },
  getResumeDownloadLink: {
    isFetching: false,
    resumeLink: null,
  },
  getDocumentsDownload: {
    isFetching: false,
    documents: null,
  },
  updateEmployeeSkillsSetResponse: {
    isFetching: false,
    response: null,
  },
  employeeCertificationResponse: {
    isFetching: false,
    certificationData: null,
  },

  employeeCommitmentResponse: {
    isFetching: false,
    certificationData: null,
  },
  createEmployeeCertificationResponse: {
    isFetching: false,
    response: null,
  },

  createEmployeeCommitmentResponse: {
    isFetching: false,
    response: null,
  },
  updateEmployeeCertificationResponse: {
    isFetching: false,
    response: null,
  },
  updateEmployeeCommitmentResponse: {
    isFetching: false,
    response: null,
  },
  educationTypeList: {
    isFetching: false,
    response: null,
  },
  employeeEducationQualificationResponse: {
    isFetching: false,
    QualificationData: null,
  },
  createEmployeeEducationQualificationResponse: {
    isFetching: false,
    response: null,
  },
  updateEmployeeEducationQualificationResponse: {
    isFetching: false,
    response: null,
  },
  uploadEmployeeEducationQualificationDocuments: {
    isFetching: false,
    response: null,
  },
  deleteEmployeeEducationQualificationDocuments: {
    isFetching: false,
    response: null,
  },
  employeeWorkExperienceResponse: {
    isFetching: false,
    WorkExperienceData: null,
  },
  createEmployeeWorkExperienceResponse: {
    isFetching: false,
    response: null,
  },
  updateEmployeeWorkExperienceResponse: {
    isFetching: false,
    response: null,
  },
  getEmployeesOfProjectResponse: {
    isFetching: false,
    projectEmployees: null,
  },
  
  createWorkModeResponse: {
    isFetching: false,
    response: null,
  },
  createWorkShiftResponse: {
    isFetching: false,
    response: null,
  },
  getWorkShiftResponse: {
    isFetching: false,
    workShiftData: null,
  },
  BankDetails: {
    isFetching: false,
    data: {},
  },
  // EmployeeCommitment: {
  //   isFetching: false,
  //   data: {},
  // },
  getEmployeeHistory: {
    isFetching: false,
    historyData: null,
  },
  uploadEmployeePreviousCompanyDocuments: {
    isFetching: false,
    response: null,
  },
  deleteEmployeePreviousCompanyDocuments: {
    isFetching: false,
    response: null,
  },
  getEmployeePreviousCompanyDocuments: {
    isFetching: false,
    response: null,
  },
  getResignationData: {
    isFetching: false,
    resignationData: null,
  },
  getResignationMonthWiseData: {
    isFetching: false,
    resignationMonthWiseData: null,
  },
  getResignationReportData: {
    isFetching: false,
    resignationReport: null,
  },
  getNewJoineeListData: {
    isFetching: false,
    newJoineeList: null,
  },
  getRemoteEmployees: {
    isFetching: false,
    remoteEmployee: null,
  },
  getAllPosts: {
    isFetching: false,
    allPosts: null,
  },
  uploadEmployeeCertificationDocuments: {
    isFetching: false,
    response: null,
  },
  deleteEmployeeCertificationDocuments: {
    isFetching: false,
    response: null,
  },
  getUserList: {
    isFetching: false,
    userList: null,
  },
  updateUser: {
    isFetching: false,
    response: null,
  },
  getUserById: {
    isFetching: false,
    userById: null,
  },
  approverHistory: {
    isFetching: false,
    approverData: null,
  }, 
  holidayHistory: {
    isFetching: false,
    approverData: null,
  },
  resetUserLock: {
    isFetching: false,
    data: null,
  },
  loading: false,
  error: null,
  success: false,
};

export const employeeNewSlice = createSlice({
  name: "employeeNew",
  initialState,
  reducers: {
    clearResumeDownloadLink: (state) => {
      state.getResumeDownloadLink = {
        isFetching: false,
        resumeLink: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreviousCompanyDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeePreviousCompanyDocuments = {
          isFetching: true,
          response: null,
        };
      })

      .addCase(getPreviousCompanyDocuments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getEmployeePreviousCompanyDocuments = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getPreviousCompanyDocuments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getEmployeePreviousCompanyDocuments = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getResignationData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getResignationData = {
          isFetching: true,
          resignationData: null,
        };
      })

      .addCase(getResignationData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getResignationData = {
          isFetching: false,
          resignationData: payload,
        };
        state.error = null;
      })
      .addCase(getResignationData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getResignationData = {
          isFetching: false,
          resignationData: null,
        };
        state.success = false;
      })
      .addCase(getResignationMonthWiseData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getResignationMonthWiseData = {
          isFetching: true,
          resignationMonthWiseData: null,
        };
      })

      .addCase(getResignationMonthWiseData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getResignationMonthWiseData = {
          isFetching: false,
          resignationMonthWiseData: payload,
        };
        state.error = null;
      })
      .addCase(getResignationMonthWiseData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getResignationMonthWiseData = {
          isFetching: false,
          resignationMonthWiseData: null,
        };
        state.success = false;
      })
      .addCase(getResignationReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getResignationReportData = {
          isFetching: true,
          resignationReport: null,
        };
      })

      .addCase(getResignationReportData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getResignationReportData = {
          isFetching: false,
          resignationReport: payload,
        };
        state.error = null;
      })
      .addCase(getResignationReportData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getResignationReportData = {
          isFetching: false,
          resignationReport: null,
        };
        state.success = false;
      })
      .addCase(fetchNewJoineeList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getNewJoineeListData = {
          isFetching: true,
          newJoineeList: null,
        };
      })

      .addCase(fetchNewJoineeList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getNewJoineeListData = {
          isFetching: false,
          newJoineeList: payload,
        };
        state.error = null;
      })
      .addCase(fetchNewJoineeList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getNewJoineeListData = {
          isFetching: false,
          newJoineeList: null,
        };
        state.success = false;
      })
      .addCase(fetchRemoteList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getRemoteEmployees = {
          isFetching: true,
          remoteEmployee: null,
        };
      })

      .addCase(fetchRemoteList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getRemoteEmployees = {
          isFetching: false,
          remoteEmployee: payload,
        };
        state.error = null;
      })
      .addCase(fetchRemoteList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getRemoteEmployees = {
          isFetching: false,
          remoteEmployee: null,
        };
        state.success = false;
      })
      .addCase(fetchAllPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAllPosts = {
          isFetching: true,
          allPosts: null,
        };
      })
      .addCase(fetchAllPosts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAllPosts = {
          isFetching: false,
          allPosts: payload,
        };
        state.error = null;
      })
      .addCase(fetchAllPosts.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAllPosts = {
          isFetching: false,
          allPosts: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeeHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeeHistory = {
          isFetching: true,
          historyData: null,
        };
      })
      .addCase(fetchEmployeeHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getEmployeeHistory = {
          isFetching: false,
          historyData: payload,
        };
        state.error = null;
      })
      .addCase(fetchEmployeeHistory.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getEmployeeHistory = {
          isFetching: false,
          historyData: null,
        };
        state.success = false;
      })
      .addCase(getWorkShiftStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getWorkShiftResponse = {
          isFetching: true,
          workShiftData: null,
        };
      })
      .addCase(getWorkShiftStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getWorkShiftResponse = {
          isFetching: false,
          workShiftData: payload,
        };
        state.error = null;
      })
      .addCase(getWorkShiftStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getWorkShiftResponse = {
          isFetching: false,
          workShiftData: null,
        };
        state.success = false;
      })
      .addCase(getResumeDownloadLinkStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getResumeDownloadLink = {
          isFetching: true,
          resumeLink: null,
        };
      })
      .addCase(getResumeDownloadLinkStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getResumeDownloadLink = {
          isFetching: false,
          resumeLink: payload,
        };
        state.error = null;
      })
      .addCase(getResumeDownloadLinkStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getResumeDownloadLink = {
          isFetching: false,
          resumeLink: null,
        };
        state.success = false;
      })
      .addCase(getDocumentsDownloadLinkStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getDocumentsDownload = {
          isFetching: true,
          documents: null,
        };
      })
      .addCase(
        getDocumentsDownloadLinkStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getDocumentsDownload = {
            isFetching: false,
            documents: payload,
          };
          state.error = null;
        }
      )
      .addCase(getDocumentsDownloadLinkStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getDocumentsDownload = {
          isFetching: false,
          documents: null,
        };
        state.success = false;
      })
      .addCase(fetchEventListDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEventListResponse = {
          isFetching: true,
          eventList: null,
        };
      })
      .addCase(fetchEventListDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getEventListResponse = {
          isFetching: false,
          eventList: payload,
        };
        state.error = null;
      })
      .addCase(fetchEventListDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getEventListResponse = {
          isFetching: false,
          eventList: null,
        };
        state.success = false;
      })
      .addCase(getBankDetails.pending, (state) => {
        state.BankDetails.isFetching = true;
      })
      .addCase(getBankDetails.fulfilled, (state, { payload }) => {
        state.BankDetails.isFetching = true;
        state.BankDetails.data = payload;
      })
      .addCase(getBankDetails.rejected, (state) => {
        state.BankDetails.isFetching = true;
        state.BankDetails.data = {};
      })



      .addCase(createAadhaarDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createAadhaarDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createAadhaarDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createAadhaarDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createAadhaarDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createAadhaarDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createPANDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createPANDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createPANDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createPANDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createPANDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createPANDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchPANDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getPANDataResponse = {
          isFetching: true,
          panData: null,
        };
      })
      .addCase(fetchPANDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getPANDataResponse = {
          isFetching: false,
          panData: payload,
        };
        state.error = null;
      })
      .addCase(fetchPANDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getPANDataResponse = {
          isFetching: false,
          panData: null,
        };
        state.success = false;
      })
      .addCase(fetchPassportDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getPassportDataResponse = {
          isFetching: true,
          passportData: null,
        };
      })
      .addCase(fetchPassportDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getPassportDataResponse = {
          isFetching: false,
          passportData: payload,
        };
        state.error = null;
      })
      .addCase(fetchPassportDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getPassportDataResponse = {
          isFetching: false,
          passportData: null,
        };
        state.success = false;
      })
      .addCase(createPassportDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createPassportDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createPassportDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createPassportDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createPassportDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createPassportDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchPersonalDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getPersonalDataResponse = {
          isFetching: true,
          personalData: null,
        };
      })
      .addCase(fetchPersonalDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getPersonalDataResponse = {
          isFetching: false,
          personalData: payload,
        };
        state.error = null;
      })
      .addCase(fetchPersonalDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getPersonalDataResponse = {
          isFetching: false,
          personalData: null,
        };
        state.success = false;
      })
      .addCase(createPersonalDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createPersonalDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createPersonalDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createPersonalDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createPersonalDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createPersonalDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createPersonalContactDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createPersonalContactDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        createPersonalContactDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createPersonalContactDataResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        createPersonalContactDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createPersonalContactDataResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchAadhaarDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAadhaarDataResponse = {
          isFetching: true,
          aadhaarData: null,
        };
      })
      .addCase(fetchAadhaarDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAadhaarDataResponse = {
          isFetching: false,
          aadhaarData: payload,
        };
        state.error = null;
      })
      .addCase(fetchAadhaarDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAadhaarDataResponse = {
          isFetching: false,
          aadhaarData: null,
        };
        state.success = false;
      })
      .addCase(fetchSeparationDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getSeparationDataResponse = {
          isFetching: true,
          separationData: null,
        };
      })
      .addCase(fetchSeparationDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getSeparationDataResponse = {
          isFetching: false,
          separationData: payload,
        };
        state.error = null;
      })
      .addCase(fetchSeparationDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getSeparationDataResponse = {
          isFetching: false,
          separationData: null,
        };
        state.success = false;
      })
      .addCase(fetchSeparationReasonDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getSeparationReasonListResponse = {
          isFetching: true,
          separationListData: null,
        };
      })
      .addCase(
        fetchSeparationReasonDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getSeparationReasonListResponse = {
            isFetching: false,
            separationListData: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchSeparationReasonDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getSeparationReasonListResponse = {
            isFetching: false,
            separationListData: null,
          };
          state.success = false;
        }
      )
      .addCase(createSeparationDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createSeparationDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createSeparationDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createSeparationDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createSeparationDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createSeparationDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createInitationSeparationDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createInitationSeparationDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        createInitationSeparationDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createInitationSeparationDataResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        createInitationSeparationDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createInitationSeparationDataResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(createEmergencyContactDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createEmergencyContactDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        createEmergencyContactDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createEmergencyContactDataResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        createEmergencyContactDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createEmergencyContactDataResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchEmergencyContactDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmergencyContactReasonListResponse = {
          isFetching: true,
          emergencyContactListData: null,
        };
      })
      .addCase(
        fetchEmergencyContactDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getEmergencyContactReasonListResponse = {
            isFetching: false,
            emergencyContactListData: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchEmergencyContactDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getEmergencyContactReasonListResponse = {
            isFetching: false,
            emergencyContactListData: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchNoticeLeaveDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getNoticeLeaveResponse = {
          isFetching: true,
          noticeLeaveData: null,
        };
      })
      .addCase(fetchNoticeLeaveDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getNoticeLeaveResponse = {
          isFetching: false,
          noticeLeaveData: payload,
        };
        state.error = null;
      })
      .addCase(fetchNoticeLeaveDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getNoticeLeaveResponse = {
          isFetching: false,
          noticeLeaveData: null,
        };
        state.success = false;
      })
      .addCase(fetchStateListDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getStateListResponse = {
          isFetching: true,
          stateListData: null,
        };
      })
      .addCase(fetchStateListDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getStateListResponse = {
          isFetching: false,
          stateListData: payload,
        };
        state.error = null;
      })
      .addCase(fetchStateListDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getStateListResponse = {
          isFetching: false,
          stateListData: null,
        };
        state.success = false;
      })
      .addCase(fetchCityListDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getCityListResponse = {
          isFetching: true,
          cityListData: null,
        };
      })
      .addCase(fetchCityListDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getCityListResponse = {
          isFetching: false,
          cityListData: payload,
        };
        state.error = null;
      })
      .addCase(fetchCityListDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getCityListResponse = {
          isFetching: false,
          cityListData: null,
        };
        state.success = false;
      })
      .addCase(fetchBasicProfileDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getBasicResponse = {
          isFetching: true,
          basicData: null,
        };
      })
      .addCase(fetchBasicProfileDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getBasicResponse = {
          isFetching: false,
          basicData: payload,
        };
        state.error = null;
      })
      .addCase(fetchBasicProfileDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getBasicResponse = {
          isFetching: false,
          basicData: null,
        };
        state.success = false;
      })
      .addCase(fetchProfileCompletedDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getProfileResponse = {
          isFetching: true,
          profileCompleted: null,
        };
      })
      .addCase(
        fetchProfileCompletedDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getProfileResponse = {
            isFetching: false,
            profileCompleted: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchProfileCompletedDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getProfileResponse = {
            isFetching: false,
            profileCompleted: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchBasicProfileImageStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getBasicImageResponse = {
          isFetching: true,
          image: null,
        };
      })
      .addCase(fetchBasicProfileImageStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getBasicImageResponse = {
          isFetching: false,
          image: payload,
        };
        state.error = null;
      })
      .addCase(fetchBasicProfileImageStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getBasicImageResponse = {
          isFetching: false,
          image: null,
        };
        state.success = false;
      })
      .addCase(fetchCompanyListDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getCompanyListResponse = {
          isFetching: true,
          companyListData: null,
        };
      })
      .addCase(fetchCompanyListDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getCompanyListResponse = {
          isFetching: false,
          companyListData: payload,
        };
        state.error = null;
      })
      .addCase(fetchCompanyListDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getCompanyListResponse = {
          isFetching: false,
          companyListData: null,
        };
        state.success = false;
      })
      .addCase(fetchApproverByIdStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getApproverByEmployeeIdResponse = {
          isFetching: true,
          approverByEmployeeId: null,
        };
      })
      .addCase(fetchApproverByIdStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getApproverByEmployeeIdResponse = {
          isFetching: false,
          approverByEmployeeId: payload,
        };
        state.error = null;
      })
      .addCase(fetchApproverByIdStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getApproverByEmployeeIdResponse = {
          isFetching: false,
          approverByEmployeeId: null,
        };
        state.success = false;
      })

      .addCase(fetchAllEmployeesData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAllEmployeeResponse = {
          isFetching: true,
          allEmployeeData: null,
        };
      })
      .addCase(fetchAllEmployeesData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAllEmployeeResponse = {
          isFetching: false,
          allEmployeeData: payload,
        };
        state.error = null;
      })
      .addCase(fetchAllEmployeesData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAllEmployeeResponse = {
          isFetching: false,
          allEmployeeData: null,
        };
        state.success = false;
      })
      .addCase(fetchVaccineDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.vaccinationDetailsResponse = {
          isFetching: true,
          vaccineData: null,
        };
      })
      .addCase(fetchVaccineDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.vaccinationDetailsResponse = {
          isFetching: false,
          vaccineData: payload,
        };
        state.error = null;
      })
      .addCase(fetchVaccineDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.vaccinationDetailsResponse = {
          isFetching: false,
          vaccineData: null,
        };
        state.success = false;
      })
      .addCase(fetchFamilyDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.familyDetailsResponse = {
          isFetching: true,
          familyData: null,
        };
      })
      .addCase(fetchFamilyDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.familyDetailsResponse = {
          isFetching: false,
          familyData: payload,
        };
        state.error = null;
      })
      .addCase(fetchFamilyDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.familyDetailsResponse = {
          isFetching: false,
          familyData: null,
        };
        state.success = false;
      })
      .addCase(createVaccineDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createVaccineDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createVaccineDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createVaccineDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createVaccineDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createVaccineDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateLeaveApproverDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateLeaveApproverDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateLeaveApproverDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateLeaveApproverDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateLeaveApproverDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateLeaveApproverDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateBasicProfileDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateBasicDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateBasicProfileDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateBasicDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateBasicProfileDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateBasicDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateEmploymentDetailsDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateEmploymentDetailsResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        updateEmploymentDetailsDataStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateEmploymentDetailsResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        updateEmploymentDetailsDataStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.updateEmploymentDetailsResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(updateBioDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateBioDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateBioDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateBioDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateBioDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateBioDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateImageDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateImageResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateImageDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateImageResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateImageDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateImageResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateResumeDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateResumeResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateResumeDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateResumeResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateResumeDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateResumeResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateJobProfileDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateJobProfileResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateJobProfileDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateJobProfileResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateJobProfileDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateJobProfileResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateVaccineDataStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateVaccineDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateVaccineDataStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateVaccineDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateVaccineDataStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateVaccineDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createFamilyDetailsStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createFamilyDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createFamilyDetailsStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createFamilyDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createFamilyDetailsStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createFamilyDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateFamilyDetailsStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateFamilyDataResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateFamilyDetailsStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateFamilyDataResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateFamilyDetailsStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateFamilyDataResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updatePassportUploadStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.uploadPassportData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updatePassportUploadStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.uploadPassportData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updatePassportUploadStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.uploadPassportData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updatePANUploadStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.uploadPANData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updatePANUploadStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.uploadPANData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updatePANUploadStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.uploadPANData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateDocumentUploadStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.uploadDocumentsData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateDocumentUploadStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.uploadDocumentsData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateDocumentUploadStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.uploadDocumentsData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createAadhaarUploadStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createAadhaarResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createAadhaarUploadStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createAadhaarResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createAadhaarUploadStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createAadhaarResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deletePANStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deletePANData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deletePANStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deletePANData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deletePANStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deletePANData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteDocumentsStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteDocuments = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteDocumentsStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteDocuments = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteDocumentsStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteDocuments = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deletePassportStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deletePassportData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deletePassportStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deletePassportData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deletePassportStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deletePassportData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteAadhaarStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteAadhaarData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteAadhaarStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteAadhaarData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteAadhaarStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteAadhaarData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeeSkillsSet.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employeeSkillsSetResponse = {
          isFetching: true,
          employeeSkillsSetData: null,
        };
      })
      .addCase(fetchEmployeeSkillsSet.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employeeSkillsSetResponse = {
          isFetching: false,
          employeeSkillsSetData: payload,
        };
        state.error = null;
      })
      .addCase(fetchEmployeeSkillsSet.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.employeeSkillsSetResponse = {
          isFetching: false,
          employeeSkillsSetData: null,
        };
        state.success = false;
      })
      .addCase(fetchWorkModePopUp.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.popUpResponse = {
          isFetching: true,
          popUpData: null,
          isSuccess: false,
        };
      })
      .addCase(fetchWorkModePopUp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.popUpResponse = {
          isFetching: false,
          popUpData: payload,
          isSuccess: true,
        };
        state.error = null;
      })
      .addCase(fetchWorkModePopUp.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.popUpResponse = {
          isFetching: false,
          popUpData: null,
          isSuccess: false,
        };
        state.success = false;
      })
      .addCase(createEmployeeSkillsSetStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createEmployeeSkillsSetResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createEmployeeSkillsSetStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createEmployeeSkillsSetResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createEmployeeSkillsSetStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createEmployeeSkillsSetResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateEmployeeSkillsSetStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateEmployeeSkillsSetResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateEmployeeSkillsSetStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateEmployeeSkillsSetResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateEmployeeSkillsSetStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateEmployeeSkillsSetResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeeCertification.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employeeCertificationResponse = {
          isFetching: true,
          employeeCertificationData: null,
        };
      })
      .addCase(fetchEmployeeCertification.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employeeCertificationResponse = {
          isFetching: false,
          employeeCertificationData: payload,
        };
        state.error = null;
      })
      .addCase(fetchEmployeeCertification.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.employeeCertificationResponse = {
          isFetching: false,
          employeeCertificationData: null,
        };
        state.success = false;
      })

      .addCase(getEmployeeCommitment.pending, (state) => {
        // state.EmployeeCommitment.isFetching = true;
        state.loading = true;
        state.error = null;
        state.employeeCommitmentResponse = {
          isFetching: true,
          employeeCommitment: null
        };
      })
      .addCase(getEmployeeCommitment.fulfilled, (state, { payload }) => {
        // state.EmployeeCommitment.isFetching = true;
        // state.EmployeeCommitment.data = payload;
        state.loading = false;
        state.success = true;
        state.employeeCommitmentResponse = {
          isFetching: false,
          employeeCommitment:payload
        };
        state.success = false;
      })

      .addCase(getEmployeeCommitment.rejected, (state,{payload}) => {
        state.loading = false;
        state.error = payload;
        state.employeeCommitmentResponse = {
          isFetching: false,
          employeeCommitment:null,
        };
        state.success = false;
        // state.EmployeeCommitment.isFetching = true;
        // state.EmployeeCommitment.data = {};
      })
      .addCase(createEmployeeCertificationStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createEmployeeCertificationResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        createEmployeeCertificationStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createEmployeeCertificationResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        createEmployeeCertificationStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createEmployeeCertificationResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )

      .addCase(createEmployeeCommitment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createEmployeeCommitmentResponse = {
          isFetching: true,
          response: null,
        };
      })
    
      .addCase(
        createEmployeeCommitment.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createEmployeeCommitmentResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        createEmployeeCommitment.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createEmployeeCommitmentResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )

      
      .addCase(updateEmployeeCertificationStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateEmployeeCertificationResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        updateEmployeeCertificationStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateEmployeeCertificationResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        updateEmployeeCertificationStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.updateEmployeeCertificationResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(updateEmployeeCommitment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateEmployeeCommitmentResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        updateEmployeeCommitment.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateEmployeeCommitmentResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        updateEmployeeCommitment.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.updateEmployeeCommitmentResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchEducationType.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.educationTypeList = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(fetchEducationType.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.educationTypeList = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(fetchEducationType.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.educationTypeList = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeeEducationQualification.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employeeEducationQualificationResponse = {
          isFetching: true,
          QualificationData: null,
        };
      })
      .addCase(
        fetchEmployeeEducationQualification.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.employeeEducationQualificationResponse = {
            isFetching: false,
            QualificationData: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchEmployeeEducationQualification.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.employeeEducationQualificationResponse = {
            isFetching: false,
            QualificationData: null,
          };
          state.success = false;
        }
      )
      .addCase(createEmployeeEducationQualificationStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createEmployeeEducationQualificationResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        createEmployeeEducationQualificationStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createEmployeeEducationQualificationResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        createEmployeeEducationQualificationStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createEmployeeEducationQualificationResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(updateEmployeeEducationQualificationStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateEmployeeEducationQualificationResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        updateEmployeeEducationQualificationStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateEmployeeEducationQualificationResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        updateEmployeeEducationQualificationStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.updateEmployeeEducationQualificationResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(uploadEducationDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.uploadEmployeeEducationQualificationDocuments = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(uploadEducationDocuments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.uploadEmployeeEducationQualificationDocuments = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(uploadEducationDocuments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.uploadEmployeeEducationQualificationDocuments = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeeWorkExperience.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employeeWorkExperienceResponse = {
          isFetching: true,
          WorkExperienceData: null,
        };
      })
      .addCase(fetchEmployeeWorkExperience.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employeeWorkExperienceResponse = {
          isFetching: false,
          WorkExperienceData: payload,
        };
        state.error = null;
      })
      .addCase(fetchEmployeeWorkExperience.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.employeeWorkExperienceResponse = {
          isFetching: false,
          WorkExperienceData: null,
        };
        state.success = false;
      })
      .addCase(createEmployeeWorkExperienceStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createEmployeeWorkExperienceResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        createEmployeeWorkExperienceStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createEmployeeWorkExperienceResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        createEmployeeWorkExperienceStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createEmployeeWorkExperienceResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(updateEmployeeWorkExperienceStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateEmployeeWorkExperienceResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        updateEmployeeWorkExperienceStore.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateEmployeeWorkExperienceResponse = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        updateEmployeeWorkExperienceStore.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.updateEmployeeWorkExperienceResponse = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(getEmployeesOfProject.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeesOfProjectResponse = {
          isFetching: true,
          projectEmployees: null,
        };
      })
      .addCase(getEmployeesOfProject.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getEmployeesOfProjectResponse = {
          isFetching: false,
          projectEmployees: payload,
        };
        state.error = null;
      })
      .addCase(getEmployeesOfProject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getEmployeesOfProjectResponse = {
          isFetching: false,
          projectEmployees: null,
        };
        state.success = false;
      })

      // .addCase(getEmployeeCommitment.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      //   state.getEmployeeCommitmentResponse = {
      //     isFetching: true,
      //     employeeCommitmentData: null,
      //   };
      // })
      // .addCase(getEmployeeCommitment.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   state.success = true;
      //   state.getEmployeeCommitmentResponse = {
      //     isFetching: false,
      //     employeeCommitmentData: payload,
      //   };
      //   state.error = null;
      // })
      // .addCase(getEmployeeCommitment.rejected, (state, { payload }) => {
      //   state.loading = false;
      //   state.error = payload;
      //   state.getEmployeeCommitmentResponse = {
      //     isFetching: false,
      //     employeeCommitmentData: null,
      //   };
      //   state.success = false;
      // })

      .addCase(createWorkModeStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createWorkModeResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createWorkModeStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createWorkModeResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createWorkModeStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createWorkModeResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createWorkShiftStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createWorkShiftResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createWorkShiftStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createWorkShiftResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createWorkShiftStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createWorkShiftResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteEducationDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteEmployeeEducationQualificationDocuments = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteEducationDocuments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteEmployeeEducationQualificationDocuments = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteEducationDocuments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteEmployeeEducationQualificationDocuments = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(uploadPreviousCompanyDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.uploadEmployeePreviousCompanyDocuments = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        uploadPreviousCompanyDocuments.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.uploadEmployeePreviousCompanyDocuments = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        uploadPreviousCompanyDocuments.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.uploadEmployeePreviousCompanyDocuments = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(deletePreviousCompanyDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteEmployeePreviousCompanyDocuments = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        deletePreviousCompanyDocuments.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.deleteEmployeePreviousCompanyDocuments = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        deletePreviousCompanyDocuments.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.deleteEmployeePreviousCompanyDocuments = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(uploadCertificationDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.uploadEmployeeCertificationDocuments = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(uploadCertificationDocuments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.uploadEmployeeCertificationDocuments = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(uploadCertificationDocuments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.uploadEmployeeCertificationDocuments = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteCertificationDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteEmployeeCertificationDocuments = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteCertificationDocuments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteEmployeeCertificationDocuments = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteCertificationDocuments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteEmployeeCertificationDocuments = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchUserListData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getUserList = {
          isFetching: true,
          userList: null,
        };
      })
      .addCase(fetchUserListData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getUserList = {
          isFetching: false,
          userList: payload,
        };
        state.error = null;
      })
      .addCase(fetchUserListData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getUserList = {
          isFetching: false,
          userList: null,
        };
        state.success = false;
      })
      .addCase(updateUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateUser = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateUserById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateUser = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateUserById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateUser = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getUserById = {
          isFetching: true,
          userById: null,
        };
      })
      .addCase(fetchUserById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getUserById = {
          isFetching: false,
          userById: payload,
        };
        state.error = null;
      })
      .addCase(fetchUserById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getUserById = {
          isFetching: false,
          userById: null,
        };
        state.success = false;
      })
      .addCase(fetchApproverHistoryById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.approverHistory = {
          isFetching: true,
          approverData: null,
        };
      })
      .addCase(fetchApproverHistoryById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.approverHistory = {
          isFetching: false,
          approverData: payload,
        };
        state.error = null;
      })
      .addCase(fetchApproverHistoryById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.approverHistory = {
          isFetching: false,
          approverData: null,
        };
        state.success = false;
      })
      // 

      .addCase(fetchHolidayHistoryById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.holidayHistory = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(fetchHolidayHistoryById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.holidayHistory = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(fetchHolidayHistoryById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.holidayHistory = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      })

      .addCase(resetLockUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.resetUserLock = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(resetLockUpdate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.resetUserLock = {
          isFetching: false,
          data: payload,
        };
        state.error = null;
      })
      .addCase(resetLockUpdate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.resetUserLock = {
          isFetching: false,
          data: null,
        };
        state.success = false;
      });
  },
});
export const { clearResumeDownloadLink } = employeeNewSlice.actions;
export default employeeNewSlice.reducer;
