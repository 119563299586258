import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { ClickAwayListener, Pagination, Stack } from "@mui/material";
import ProjectListDetails from "./ProjectListDetails";
import Loader from "../../Loader/Loader";
import CreateProject from "./CreateProject";
import { fetchAllProjects } from "../../../store/slices/ProjectSlice";
import { fetchAllClientsName } from "../../../store/slices/masterSlice";
import { componentDisplayAccess } from "../../../utils/userPermission";

const ProjectDashboard = () => {
  const [createProject, setCreateProject] = useState(false);
  const [showProjectDetail, setshowProjectDetail] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [toggle, setToggle] = useState(false);
  const [toggleClientFilter, setToggleClientFilter] = useState(false);
  const [filterValue, setFilterValue] = useState({
    label: "All Clients",
    value: "",
  });
  const projectAccess = componentDisplayAccess({
    component: "Project",
  });

  const [isActive, setIsActive] = useState("Active");
  const [sort, setSort] = useState("ASC");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [pageCount, setPageCount] = useState(10);
  const [count, setCount] = useState(false);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const handleShowProjects = (event, data) => {
    setProjectData(event);
    if (Number(event) === Number(data?.project_id)) {
      setshowProjectDetail(true);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const ref = useRef(null);

  useEffect(() => {
    dispatch(
      fetchAllProjects({
        filterValue: isActive,
        sort,
        clientId: filterValue?.value,
        search,
        page: page,
        limit: limit,
      })
    );
    dispatch(fetchAllClientsName());
  }, [isActive, dispatch, sort, filterValue, search, page, limit]);
  let projectListData = useSelector((state) => state?.Project);
  const projectList = projectListData?.allProjects?.data?.data;
  useEffect(() => {
    if (projectList) {
      setPageCount(projectList?.totalPages);
      setCount(projectList?.totalItems);
    }
  }, [projectList]);
  const isFetchingProjectList = useSelector(
    (state) => state?.MyTeamSlice?.projectList?.isFetching
  );
  const allProjectData = {
    filterValue: isActive,
    sort,
    clientId: filterValue?.value,
    search,
    page: page,
    limit: limit,
  };
  const getClientsName = useSelector(
    (state) => state?.masterList?.clientList?.clientData?.data
  );
  const handleSearchChange = (event) => {
    const delayInput = setTimeout(() => {
      setSearch(event?.target?.value);
    }, 800);
    return () => clearTimeout(delayInput);
  };
  return (
    <>
      {showProjectDetail ? (
        <ProjectListDetails
          projectData={projectData}
          setProjectData={setProjectData}
          setshowProjectDetail={setshowProjectDetail}
          allProjectData={allProjectData}
        />
      ) : (
        <div>
          <div className="projectStatusTab mt-4 px-3">
            <ul className="flex items-center bg-white border-1 border-gray-200">
              <li
                id="Active"
                onClick={(e) => {
                  setIsActive(e?.target?.id);
                  setSort("ASC");
                  setSearch("");
                  setPage(1);
                  setLimit(15);
                }}
                className={`${
                  isActive === "Active" ? "active: bg-blue-200" : ""
                } py-2 px-3 text-sm cursor-pointer`}
              >
                Active Projects
              </li>
              <li
                id="All"
                onClick={(e) => {
                  setIsActive(e?.target?.id);
                  setSort("ASC");
                  setSearch("");
                  setPage(1);
                  setLimit(15);
                }}
                className={`${
                  isActive === "All" ? "active: bg-blue-200" : ""
                } py-2 px-3 text-sm cursor-pointer`}
              >
                All Projects
              </li>
              <li
                id="Archived"
                onClick={(e) => {
                  setIsActive(e?.target?.id);
                  setSort("ASC");
                  setSearch("");
                  setPage(1);
                  setLimit(15);
                }}
                className={`${
                  isActive === "Archived" ? "active: bg-blue-200" : ""
                } py-2 px-3 text-sm cursor-pointer`}
              >
                Archived Projects
              </li>
              <li
                id="initiate"
                onClick={(e) => {
                  setIsActive(e?.target?.id);
                  setSort("ASC");
                  setSearch("");
                  setPage(1);
                  setLimit(15);
                }}
                className={`${
                  isActive === "initiate" ? "active: bg-blue-200" : ""
                } py-2 px-3 text-sm cursor-pointer`}
              >
                Initiated Projects
              </li>
            </ul>
          </div>
          <div className="projectList">
            <div className="projectListHeader">
              <div className="pListHeaderContent">
                <div className="pListHeaderContentHeader">Project List</div>
                <div className="pListHeaderContentDesc">
                  The following are the list of projects which are in progress.
                </div>
              </div>
              {projectAccess?.createAccess ? (
                <div
                  className="font-sm text-sm border-1 border-blue-600 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
                  onClick={() => setCreateProject((prev) => !prev)}
                >
                  <i className="fa-light fa-plus pr-1"></i> Add Project
                </div>
              ) : null}
            </div>
          </div>
          <div className="px-3">
            <div className="projectListFilter bg-white flex items-center border-[1px] border-gray-300">
              <ClickAwayListener
                onClickAway={() => setToggleClientFilter(false)}
              >
                <div className="projectList relative cursor-pointer ">
                  <div
                    onClick={() => setToggleClientFilter(!toggleClientFilter)}
                    className="inline-flex items-center justify-between gap-4 border-r border-gray-300 py-3 px-3 w-52"
                  >
                    <div className="text-sm text-gray-700">
                      {filterValue?.label}
                    </div>
                    <div className="text-sm text-gray-500">
                      {toggleClientFilter ? (
                        <i className="fa-light fa-chevron-up"></i>
                      ) : (
                        <i className="fa-light fa-chevron-down"></i>
                      )}
                    </div>
                  </div>
                  {toggleClientFilter && (
                    <ul className="border border-gray-500 rounded-md mt-1 absolute bg-white w-52 top-[50px] z-10 overflow-y-scroll max-h-[300px]">
                      <li
                        onClick={() => {
                          setFilterValue({
                            label: "All Clients",
                            value: "",
                          });
                          setToggleClientFilter(false);
                        }}
                        className="text-sm border-b border-gray-200 py-2 px-3"
                      >
                        All Clients
                      </li>
                      {getClientsName &&
                        getClientsName?.map((item, key) => (
                          <li
                            key={key}
                            onClick={() => {
                              // let clientDetail = projectList?.item?.filter(
                              //   (v) =>
                              //     v?.Client?.client_name === item?.client_name
                              // );
                              setFilterValue({
                                label: item?.client_name,
                                value: item?.client_id,
                              });
                              setToggleClientFilter(false);
                            }}
                            className="text-sm border-b border-gray-200 py-2 px-3"
                          >
                            {item?.client_name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
              <div className="px-3">
                <div className="text-gray-500 relative">
                  <span
                    className="text-gray-500 cursor-text"
                    onClick={() => ref.current.focus()}
                  >
                    <i className="fa-light fa-magnifying-glass"></i>
                  </span>
                  <input
                    ref={ref}
                    onChange={handleSearchChange}
                    type="text"
                    name="search input"
                    placeholder="Search By Project"
                    className="text-sm border-none pl-1"
                  />
                </div>
              </div>
            </div>
            <div className="projectListTable relative overflow-x-auto rounded-md border-1">
              <table className=" w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap">
                <thead className="text-xs bg-gray-50 text-gray-700 uppercase border-b border-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-2">
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          sort === "ASC" ? setSort("DESC") : setSort("ASC")
                        }
                      >
                        <span className="mr-1">PROJECT</span>
                        {sort === "ASC" ? (
                          <i className="fa-light fa-arrow-up-a-z"></i>
                        ) : (
                          <i className="fa-light fa-arrow-up-z-a"></i>
                        )}
                      </span>
                    </th>
                    <th scope="col" className="px-6 py-2">
                      CLIENT
                    </th>
                    <th scope="col" className="px-6 py-2">
                      STATUS
                    </th>
                    <th scope="col" className="px-6 py-2">
                      START DATE
                    </th>
                    <th scope="col" className="px-6 py-2">
                      END DATE
                    </th>
                    <th scope="col" className="px-6 py-2">
                      PROJECT MANAGER
                    </th>
                  </tr>
                </thead>
                {isFetchingProjectList && <Loader />}
                <tbody>
                  {projectList &&
                    projectList?.item?.map((items, key) => {
                      return (
                        <tr
                          key={items?.project_id}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <td className="p-3">
                            <span
                              id={items?.project_id}
                              onClick={(e) =>
                                handleShowProjects(items?.project_id, items)
                              }
                              className="text-blue-600 cursor-pointer capitalize"
                            >
                              {items?.project_name ?? "-"}
                            </span>
                          </td>
                          <td className="p-3">
                            {items?.Client?.client_name ?? "-"}
                          </td>
                          <td className="p-3">
                            <span
                              className={
                                items?.status === "InProgress"
                                  ? "border-1 border-gray-300 bg-green-50 rounded-full px-3 text-sm text-gray-950"
                                  : items?.status === "Initiate"
                                  ? "border-1 border-yellow-200 bg-gray-50 rounded-full px-3 text-sm text-gray-950"
                                  : items?.status === "Completed"
                                  ? "border-1 border-green-300 bg-green-50 rounded-full px-3 text-sm text-gray-950"
                                  : "border-1 border-red-300 bg-red-50 rounded-full px-3 text-sm text-gray-950"
                              }
                            >
                              {items?.status === "InProgress"
                                ? "InProgress"
                                : items?.status === "Initiate"
                                ? "Initiated"
                                : items?.status === "Completed"
                                ? "Completed"
                                : "Cancelled"}
                            </span>
                          </td>
                          <td className="p-3">
                            {items?.start_date
                              ? dayjs(items?.start_date)?.format("DD-MMM-YYYY")
                              : "-"}
                          </td>
                          <td className="p-3">
                            {items?.end_date
                              ? dayjs(items?.end_date)?.format("DD-MMM-YYYY")
                              : "-"}
                          </td>
                          <td className="p-3">
                            {items?.Employee?.employee_name ?? "-"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="px-3">
            <div className="timeSheetPagination bg-transparent">
              <Stack spacing={2}>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  className="paginationCount"
                />
              </Stack>
              <div className="totalNoOfPages ms-3">
                On Page:
                <span className="totalCount">
                  {page} of {pageCount}
                </span>
              </div>
              <label className="PaginationLabelWhere px-2">|</label>
              <div className="totalNoOfPages">
                Total Count: <span className="totalCount">{count}</span>
              </div>
              <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div
                  className="paginationLimitBox"
                  onClick={() => setToggle(!toggle)}
                >
                  {limit}
                  {toggle ? (
                    <i className="fa-light fa-chevron-up ms-2"></i>
                  ) : (
                    <i className="fa-light fa-chevron-down ms-2"></i>
                  )}
                  {toggle && (
                    <ul className="paginationLimitBoxDropdown">
                      {[10, 15, 25, 50, 75, 100]?.map((list) => {
                        return (
                          <li
                            value={list}
                            onClick={(e) => {
                              setLimit(e?.target?.value);
                              setPage(1);
                            }}
                          >
                            {list}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      )}

      {createProject && (
        <CreateProject
          createProject={createProject}
          setCreateProject={setCreateProject}
          allProjectData={allProjectData}
        />
      )}
    </>
  );
};

export default ProjectDashboard;
